.searchbox{

  width: 400px;
  height: 70px;

  display : flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  border: none;
  outline:none;

  .searchbox__input{
    width: 100%;
    height: 100%;
    background: transparent;
    color: white;
    padding: 0px 20px;
    font-size: 30px;
    opacity: 0;
    transition: 3s;
  }
  .searchbox__input.open{
    width: 100%;
    height: 100%;
    background: transparent;
    color: white;
    padding: 0px 20px;
    font-size: 30px;
    background: black;
    opacity: 1;
    transition: 3s;

  }
  .searchbox__btn{

    padding: 20px 20px;
    border-radius: 15px;
    font-size: 25px;
    border: none;
    outline:none;
    margin-right: 40%;
    transition: 3s;
  }
  .searchbox__btn.open{
    margin-right:0;

  }

};
